<template>
  <div class="news-article">
    <el-skeleton :loading="loading" animated>
      <template>
        <h1>{{detail&&detail.title}}</h1>
        <div class="article-meta">
          <i class="el-icon-time"></i>{{detail&&detail.date | dateformat('YYYY-MM-DD HH:mm:ss')}}
        </div>
        <div class="editer-html" v-html="detail&&detail.content">
          <!-- 新闻内容 -->
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
import { newDetial } from "@/api/news";
export default {
  name: "NewsDetail",
  data() {
    return {
      detail: null,
      loading: false,
    };
  },
  created() {
    this.getDetial(this.$route.params.id);
  },
  methods: {
    getDetial(id) {
      this.loading = true;
      newDetial(id)
        .then((response) => {
          this.detail = response;
          this.$store.commit("app/DETAIL_TITLE", this.detail.title);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.news-article {
  line-height: 25px;
  padding: 10px 0;
  .article-meta {
    color: #6d6d6d;
    margin: 10px 0;
    i {
      margin-right: 5px;
    }
  }
}
</style>
